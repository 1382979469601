.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 999;
}

.ant-modal {
    z-index: 1000;
}

.ant-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}