@keyframes blink {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.animated-button {
  animation: blink 1.5s infinite;
}

.actualizations-container {
  padding: 10px 0 0 10px;
  border-radius: 30px;
  overflow: hidden;
  margin: 0;
}

.update-card {
  background-color: #ffffff;
  border-left: 8px solid #1890ff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  margin: 0 0 15px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.update-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), 0 10px 25px rgba(0, 0, 0, 0.2);
}

.updates-list {
  max-height: 400px;
  overflow-y: auto;
  margin: 0;
  padding: 0 10px;
  box-sizing: border-box;
}

.update-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.update-type {
  font-size: 16px;
  font-weight: bold;
  color: #1890ff;
}

.update-date {
  font-size: 12px;
  color: #333333;
}

.update-description {
  font-size: 14px;
  color: #333333;
  margin: 10px 0;
}

.update-version {
  font-size: 12px;
  color: #333333;
  text-align: right;
  font-weight: bold;
}

.ant-card {
  border-radius: 8px;
}

.custom-modal .ant-modal {
  border-radius: 12px;
}

.custom-modal .ant-modal-content {
  border-radius: 12px;
}

.custom-modal .ant-modal-header {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.custom-modal .ant-btn {
  color: #ffffff;
  background-color: #1890ff;
  border: none;
  border-radius: 4px;
}

.custom-modal .ant-btn:hover {
  background-color: #40a9ff;
}

.border-interface {
  border-left: 8px solid #e67e22;
}

.border-functional {
  border-left: 8px solid #3498db;
}

.border-scalability {
  border-left: 8px solid #1abc9c;
}

.border-support {
  border-left: 8px solid #e74c3c;
}